import React from "react"
import FooterLinkSection from "./FooterLinkSection"
import { socials } from "./data"

const Socials = () => (
  <FooterLinkSection { ...socials } />
)


export default Socials
