import React from "react"
import FooterLinkSection from "./FooterLinkSection"
import { contacts } from "./data"

const ContactInfo = () => (
  <FooterLinkSection { ...contacts } />
)


export default ContactInfo
